<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Pantone.svg" />
        </span>
      </div>
      <div class="alert-text">
        {{this.$route.params.TypeTestName}} at the {{this.$route.params.AgendaName}}
      </div>
    </b-alert>

    <div  class="card shadow-xs cardHover">
      <!-- Tabel -->
      <label ></label>
      <label ></label>
      <div class="form-group">
        <label for="name"></label>
        <b-form-group id="input-group-2"  label-for="input-2" class="mb-2 mr-sm-2 mb-sm-0">
       <b-form-input
         id="input-2"
         v-model="form.name"
         required
         placeholder="Search"
         class="mb-2 mr-sm-2 mb-sm-0"
       ></b-form-input>
     </b-form-group>

      </div>
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      > 
        <b-table
          id="my-table"
          :fields="fields"
          :filter="form.name"
          striped hover :items="items"
          :per-page="perPage"
          :current-page="currentPage"
        >
        <template #cell(name)="row">
          <b-card>
          <!-- <b-row class="mb-12"> -->
            <!-- <b-col sm="12" class="text-sm-center"><b>{{row.item.name}}</b></b-col> -->
          <!-- </b-row> -->
          <ul>
            <li>{{ row.item.participant_name }}</li>
          </ul>
        </b-card> 
        </template>

        <template #cell(answer_content)="row">
          <b-card>
          <ul>
            <li v-for="(value, key) in row.item.items" :key="key">Jawaban {{ value.name }}: Jumlah = {{ value.total_val }}, Deskripsi = {{ value.des }}</li>
          </ul>
        </b-card>
        </template>

      </b-table>
      </b-alert>

      <!-- Pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        size="lg"
        class="mt-3"
        align="center"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Services from "@/core/services/psikologi-api/Services";
import ApiService from "@/core/services/api.service";
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      selected: null,
      criteria: "", // Properti filter
      fields: ['name', 'answer_content'],
      items: [],
      perPage: 100,
      currentPage: 1,
      form: {
        name: '',
      },
      show: true
    };
  },
  components: {
  },
  computed: {
     rows() {
       return this.items.length
     }
   },
  methods: {
    filterTable(row, filter) {
      // Ganti row.age_from sesuai dengan kolom yang ingin di-filter
      if (row.age_from.includes(filter)) {
        return true;
      } else {
        return false;
      }
    },

    onSubmit(evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    onReset(evt) {
      evt.preventDefault()
      this.form.name = ''
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
  
    getResultCfit() {
      return new Promise((resolve, reject) => {
        let contentType = `application/form-data`;
        Services.GetId(
          ApiService,
          `result/testpapikostick`,
          this.$route.params.ScheduleID,
          contentType,
          response => {
            // Mengganti items dengan data dari API
            resolve(response.data);
            // console.log("response.data", response.data)
            this.items = this.renderData(response.data)
            // Resolve promise
          },
          err => {
            Swal.fire({
              title: "Data Not Found",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: true,
              timer: 1500
            });
            reject(err);
          }
        );
      });
    },

    renderData(dataList){
      var resultList = []
      resultList = this.groupByManual(dataList, ['participant_id', 'participant_name'])
      return resultList
    },

    groupByManual(dataSourceList, arrStringProp){
        var dataList = [];
        var size = arrStringProp.length;
        
        for(let obj of dataSourceList){
            let opt = dataList.find((p)=>{
                let string = "";
                let idx = 1;
                for(let prop of arrStringProp){
                    string+= " p['"+prop+"'] == obj['"+prop+"']";
                    if(idx<size)string += " && ";
                    idx++;
                }
                string+= " ;";
                if(string!="")return eval(string);
                else return false;
            })
    
            if(opt){
                opt.items.push(obj);
            }else{
                let newObj = {items: []};
                for(let prop of arrStringProp){
                    newObj[prop] = obj[prop];
                }
                newObj['items'].push(obj);
                dataList.push(newObj)
            }
        }
        return dataList;
    },

    test(row){
      var result = "<p>"
        //  var result = "<ul>"
         for(var i=0; i<row.item.items.length; i++){
          var answerObj = row.item.items[i]
            result = result +`${answerObj.answer_content} : ${answerObj.answer_total} total <br>`
          //  result = result +`<li>${answerObj.answer_content} : ${answerObj.answer_total} total</li>`
        }
        result = result+ "</p>"
        return result
    },

    loadData() {
      this.getResultCfit()
        .then(() => {
          // Lakukan sesuatu setelah data dimuat
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "schedule", route: "/schedule" },
      { title: "Report Test PapiKostik" }
    ]);
    this.loadData();
  }
};
</script>
<style>
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.form-group label {
  margin-right: 10px;
  flex-grow: 1; /* Optional: Add this to make sure the label takes up the available space on the left */
}
.form-group input {
  flex-grow: 2; /* Optional: Add this to make sure the input field takes up more space on the right */
}
</style>
